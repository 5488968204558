/**
 *
 * Schedules selectors
 * @author Chad Watson
 *
 */

import { Map } from "immutable";
import { availableXRNumbers, availableXT75Numbers } from "models/schedules/xr";
import {
  always,
  compose,
  converge,
  curry,
  defaultTo,
  ifElse,
  inc,
  isNil,
} from "ramda";
import store from "store";
import {
  immutableFind,
  immutableGet,
  immutableKeySeq,
  immutableToJS,
  safeBooleanGetter,
  safeGetter,
  safeImmutableGet,
} from "utils";
import {
  createCachedSelectorBySystemId,
  selectActiveSystem,
  selectSystem,
} from "../index";

const EMPTY_MAP = Map();

const selectScheduleNumberFromProps = (_, props) => props.number;

export const selectSystemSchedules = compose(
  immutableGet("xrSchedules"),
  selectSystem
);

export const selectRequestingXrSchedules = compose(
  immutableGet("requesting"),
  selectSystemSchedules
);

export const selectRefreshingXrSchedules = compose(
  immutableGet("refreshing"),
  selectSystemSchedules
);

export const selectXrSchedulesRequestError = compose(
  immutableGet("requestError"),
  selectSystemSchedules
);

export const selectCreating = compose(
  immutableGet("creating"),
  selectSystemSchedules
);

export const selectCreateErrors = compose(
  immutableGet("createErrors"),
  selectSystemSchedules
);

export const selectSavingAreaSettings = compose(
  immutableGet("savingAreaSettings"),
  selectSystemSchedules
);

export const selectSchedules = compose(
  immutableGet("byNumber"),
  selectSystemSchedules
);

export const selectNewSchedule = compose(
  safeImmutableGet("new"),
  selectSchedules
);

export const selectSchedule = converge(safeImmutableGet, [
  selectScheduleNumberFromProps,
  selectSchedules,
]);

export const selectSavingXrSchedule = compose(
  safeBooleanGetter(immutableGet("saving")),
  selectSchedule
);

export const selectDeletingXrSchedule = compose(
  safeBooleanGetter(immutableGet("deleting")),
  selectSchedule
);

export const selectXrScheduleErrors = compose(
  safeImmutableGet("errors"),
  selectSchedule
);

const DEFAULT_AVAILABLE_NUMBERS = [];
export const selectAvailableNumbers = createCachedSelectorBySystemId(
  selectSchedules,
  ifElse(
    isNil,
    always(DEFAULT_AVAILABLE_NUMBERS),
    compose(
      ifElse(
        () => selectActiveSystem(store.getState()).get("isXt75"),
        availableXT75Numbers,
        availableXRNumbers
      ),
      immutableToJS,
      immutableKeySeq
    )
  )
);

export const selectDeletingSchedule = createCachedSelectorBySystemId(
  selectSchedules,
  safeGetter(immutableFind(immutableGet("deleting")))
);

const countSchedulesForOptions = curry((optionsKey, schedules) => {
  if (!schedules) {
    return EMPTY_MAP;
  }

  return schedules.reduce(
    (counts, schedule) =>
      schedule
        .get(optionsKey)
        .reduce(
          (currentCounts, id) =>
            currentCounts.update(id, compose(inc, defaultTo(0))),
          counts
        ),
    EMPTY_MAP
  );
});

export const selectSchedulesCountByAreaId = createCachedSelectorBySystemId(
  selectSchedules,
  countSchedulesForOptions("areaIds")
);

export const selectSchedulesCountByDoorId = createCachedSelectorBySystemId(
  selectSchedules,
  countSchedulesForOptions("doorIds")
);

export const selectSchedulesCountByFavoriteId = createCachedSelectorBySystemId(
  selectSchedules,
  countSchedulesForOptions("favoriteIds")
);

export const selectSchedulesCountByOutputId = createCachedSelectorBySystemId(
  selectSchedules,
  countSchedulesForOptions("outputIds")
);

export const selectSelectedXrSchedule = compose(
  immutableGet("selectedSchedule"),
  selectSystemSchedules
);
