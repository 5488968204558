/**
 *
 * Permissions selectors
 * @author Chad Watson
 *
 *
 */
import { Map } from "immutable";
import { fullAuthority } from "models/panelPermissions";
import { allPass, compose, converge, not } from "ramda";
import { createSelector } from "reselect";
import {
  selectIsTempDealerUser,
  selectUserIsAccessOnly,
  selectUserPermissions,
} from "store/auth/selectors";
import { immutableGet, immutableIsEmpty, safeBooleanGetter } from "utils";
import {
  createCachedSelectorBySystemId,
  selectCapabilities,
  selectIsAreaSystem,
  selectSystem,
  selectSystemHasRemotePanic,
  selectSystemIdFromProps,
  systemIsXR,
  systemIsXT75,
} from "./index";
import { selectCanPOD } from "./onDemand";
import {
  selectDoorsEditingEnabled,
  selectFavoriteEditingEnabled,
  selectLightControlEnabled,
  selectLockControlEnabled,
  selectOutputOptionsEditingEnabled,
  selectRealTimeEventsEnabled,
  selectScheduleEditEnabled,
  selectThermostatControlEnabled,
  selectTrafficCountEnabled,
  selectUserCodeEditingEnabled,
  selectVideoEnabled,
} from "./servicesManager";
export const systemPermissions = immutableGet("permissions");
export const selectPermissions = createSelector(
  selectIsTempDealerUser,
  selectSystem,
  (isTempDealerUser, system) =>
    isTempDealerUser ? fullAuthority() : systemPermissions(system)
);
export const selectPermissionsReceived = compose(
  immutableGet("permissionsReceived"),
  selectSystem
);
export const selectCanViewSchedules = compose(
  immutableGet("schedules"),
  selectPermissions
);
export const selectCanViewUserCodes = compose(
  immutableGet("userCodes"),
  selectPermissions
);
export const selectCanViewProfiles = allPass([
  compose(immutableGet("profiles"), selectPermissions),
  compose(systemIsXR, selectSystem),
]);
export const selectCanViewGroups = allPass([
  selectIsAreaSystem,
  compose(systemIsXT75, selectSystem),
]);
export const selectCanGrantDoorAccess = compose(
  immutableGet("doorAccess"),
  selectPermissions
);
export const selectCanLockdown = compose(
  immutableGet("lockdown"),
  selectPermissions
);
export const selectCanSensorReset = compose(
  (permissions) => permissions.sensorReset && permissions.lockdownOverride,
  selectPermissions
);
export const selectCanLockUnlockDoor = compose(
  immutableGet("doorLockUnlock"),
  selectPermissions
);
export const selectCanBypassZones = compose(
  Boolean,
  immutableGet("bypass"),
  selectPermissions
);
export const selectSupportsPushNotifications = compose(
  safeBooleanGetter(immutableGet("supportsPushNotifications")),
  selectSystem
);
export const selectSupportsSempro = compose(
  safeBooleanGetter(immutableGet("supportsSempro")),
  selectSystem
);
const systemHasOutputs = compose(
  not,
  immutableIsEmpty,
  immutableGet("trackedOutputs")
);
export const selectHasOutputs = compose(
  safeBooleanGetter(systemHasOutputs),
  selectSystem
);

const userCanRunReportsOnSystem = (systemId, panelPermissions) =>
  panelPermissions.getIn([systemId.toString(), "reportsEnabled"]) || false;

export const selectCanRunReports = converge(userCanRunReportsOnSystem, [
  selectSystemIdFromProps,
  selectUserPermissions,
]);

const userCanSendEmergencyPanic = (systemId, panelPermissions) =>
  panelPermissions.getIn([
    systemId.toString(),
    "remotePanicEmergencyEnabled",
  ]) || false;

export const selectCanSendEmergencyPanic = converge(userCanSendEmergencyPanic, [
  selectSystemIdFromProps,
  selectUserPermissions,
]);

const userCanSendFirePanic = (systemId, panelPermissions) =>
  panelPermissions.getIn([systemId.toString(), "remotePanicFireEnabled"]) ||
  false;

export const selectCanSendFirePanic = converge(userCanSendFirePanic, [
  selectSystemIdFromProps,
  selectUserPermissions,
]);

const userCanSendPolicePanic = (systemId, panelPermissions) =>
  panelPermissions.getIn([systemId.toString(), "remotePanicPoliceEnabled"]) ||
  false;

export const selectCanSendPolicePanic = converge(userCanSendPolicePanic, [
  selectSystemIdFromProps,
  selectUserPermissions,
]);
export const selectCanViewVideo = createSelector(
  selectVideoEnabled,
  selectIsTempDealerUser,
  (videoEnabled, isTempDealerUser) => videoEnabled && !isTempDealerUser
);
export const selectServicesManagerRealTimeEventsEnabled = createSelector(
  selectRealTimeEventsEnabled,
  (realTimeEventsEnabled) => realTimeEventsEnabled
);
export const selectSystemSubItemPermissions = createCachedSelectorBySystemId(
  selectSupportsPushNotifications,
  selectSupportsSempro,
  selectCanViewVideo,
  selectFavoriteEditingEnabled,
  selectLightControlEnabled,
  selectLockControlEnabled,
  selectThermostatControlEnabled,
  selectDoorsEditingEnabled,
  selectScheduleEditEnabled,
  selectOutputOptionsEditingEnabled,
  selectUserCodeEditingEnabled,
  selectCanViewSchedules,
  selectCanViewUserCodes,
  selectCanViewProfiles,
  selectCanViewGroups,
  selectCanPOD,
  selectHasOutputs,
  selectCanRunReports,
  selectSystemHasRemotePanic,
  selectCanSendEmergencyPanic,
  selectCanSendFirePanic,
  selectCanSendPolicePanic,
  selectCapabilities,
  selectUserIsAccessOnly,
  (
    supportsPushNotifications,
    supportsSempro,
    canViewVideo,
    favoriteEditingEnabled,
    lightControlEnabled,
    lockControlEnabled,
    thermostatControlEnabled,
    doorsEditingEnabled,
    scheduleEditEnabled,
    outputOptionsEditingEnabled,
    userCodeEditingEnabled,
    canViewSchedules,
    canViewUserCodes,
    canViewProfiles,
    canViewGroups,
    canPOD,
    hasOutputs,
    userCanRunReports,
    hasRemotePanic,
    canSendEmergencyPanic,
    canSendFirePanic,
    canSendPolicePanic,
    capabilities,
    userIsAccessOnly
  ) =>
    Map({
      video: canViewVideo && !userIsAccessOnly,
      events: supportsPushNotifications && !userIsAccessOnly,
      favorites:
        favoriteEditingEnabled &&
        (lightControlEnabled || lockControlEnabled || thermostatControlEnabled),
      doors: lockControlEnabled || doorsEditingEnabled,
      thermostats: thermostatControlEnabled,
      lights: lightControlEnabled,
      schedules: scheduleEditEnabled && canViewSchedules,
      actions:
        outputOptionsEditingEnabled ||
        canViewVideo ||
        capabilities.get("userProgrammableActions"),
      outputs: hasOutputs,
      users: userCodeEditingEnabled && canViewUserCodes,
      profiles:
        userCodeEditingEnabled && scheduleEditEnabled && canViewProfiles,
      groups: userCodeEditingEnabled && scheduleEditEnabled && canViewGroups,
      reports: userCanRunReports && supportsSempro,
      notifications: supportsSempro && !userIsAccessOnly,
      settings: true,
      monitoring: canPOD,
      panic:
        hasRemotePanic &&
        (canSendEmergencyPanic || canSendFirePanic || canSendPolicePanic),
    })
);
export const selectCanViewDoors = compose(
  immutableGet("doors"),
  selectSystemSubItemPermissions
);
export const selectCanViewLights = compose(
  immutableGet("lights"),
  selectSystemSubItemPermissions
);
export const selectCanViewFavorites = compose(
  immutableGet("favorites"),
  selectSystemSubItemPermissions
);
export const selectCanViewOutputs = compose(
  immutableGet("outputs"),
  selectSystemSubItemPermissions
);
export const selectSystemSectionsPermissions = createCachedSelectorBySystemId(
  selectServicesManagerRealTimeEventsEnabled,
  selectSupportsPushNotifications,
  selectCanViewVideo,
  selectFavoriteEditingEnabled,
  selectLightControlEnabled,
  selectLockControlEnabled,
  selectThermostatControlEnabled,
  selectTrafficCountEnabled,
  selectCanGrantDoorAccess,
  selectCanLockUnlockDoor,
  selectUserIsAccessOnly,
  (
    selectServicesManagerRealTimeEventsEnabled,
    supportsPushNotifications,
    canViewVideo,
    favoriteEditingEnabled,
    lightControlEnabled,
    lockControlEnabled,
    thermostatControlEnabled,
    trafficCountEnabled,
    canGrantDoorAccess,
    canLockUnlockDoor,
    userIsAccessOnly
  ) => ({
    video: canViewVideo && !userIsAccessOnly,
    events: supportsPushNotifications && !userIsAccessOnly,
    favorites:
      favoriteEditingEnabled ||
      lightControlEnabled ||
      lockControlEnabled ||
      thermostatControlEnabled,
    doors: canGrantDoorAccess || canLockUnlockDoor,
    locks: lockControlEnabled,
    barrierOperators: lockControlEnabled,
    thermostats: thermostatControlEnabled,
    lights: lightControlEnabled,
    trafficCount: trafficCountEnabled,
    servicesManagerRealTimeEventsEnabled:
      selectServicesManagerRealTimeEventsEnabled,
  })
);
